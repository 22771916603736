.heading-about {
  font-family: "Playfair Display";
  text-align: center;
  margin-bottom: 20px;
}

.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-container {
  max-width: 1000px;
  width: 100%;
  margin: 20px auto;
  font-family: "Barlow";
  padding: 0 20px;
}

.write-up {
  width: 100%; 
  max-width: 600px;
  margin: 0 auto 20px;
  padding: 0 15px;
}

.about-image {
  width: 100%; 
  max-width: 400px; 
  height: auto; /* Ensure the image scales proportionally */
  max-height: 300px; /* Limit the height of the image */
  object-fit: cover; /* Cover the area, maintaining aspect ratio */
}

@media (min-width: 768px) {
  .about-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }

  .write-up {
    width: 60%; 
    margin-bottom: 0;
  }

  .about-image {
    width: 35%; 
    max-width: 300px; 
    max-height: 250px; /* Adjust as needed */
  }
}

.about-page a {
  text-decoration: none;
  color: inherit;
}
/* Accordion Styling */
.accordion-item .accordion-button:not(.collapsed) {
  background-color: #bea882;
  border-color: #AA8444;
  color: #fff;
}

.accordion-item .accordion-collapse .accordion-body {
  color: #333;
}

.accordion-item .accordion-button {
  border: 1px solid #AA8444;
  border-bottom: none;
}

.accordion-item .accordion-collapse {
  border: 1px solid #AA8444;
  border-top: none;
}

/* Remove default blue outline and shadow */
.accordion-item .accordion-button:focus,
.accordion-item .accordion-button:focus-visible {
  box-shadow: none !important; /* Removes the default focus shadow */
  outline: none; /* Removes the default focus outline */
}

/* Optional: Remove blue highlights on all focusable elements */
input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important; /* Remove focus shadow */
  outline: none !important; /* Remove focus outline */
}
