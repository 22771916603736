.brandLogo {
    width: 150px;
    height: auto;
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
}

.contact-info p {
    margin: 5px 0;
}

.navbar-nav {
    margin-top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: 'Cormorant Garamond Bold';
    font-size: 22px;
    padding: 0;
    color: #402E32;
}

.navbar-nav .nav-link {
    color: #402E32;
    position: relative;
    text-decoration: none;
    margin: 0 15px; 
}

.navbar-nav .nav-link:hover {
    color: #A28767;
}

.navbar-nav .nav-link:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: #AC3F1F;
}

@media (max-width: 992px) {
    .contact-info.d-lg-none {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-left: auto;
    }

    .contact-info.d-none.d-lg-flex {
        display: none;
    }
}
