/* Importing Fonts */
@font-face {
  font-family: 'Barlow';
  src: url('./Barlow/Barlow-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Medium';
  src: url('./Barlow/Barlow-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display';
  src: url('./Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
}

/* Heading Styling */
.heading-contact {
  font-family: "Playfair Display";
}

/* Flexbox Styling */
.flexbox {
  display: flex;
  flex-wrap: wrap;
}

/* Contact Form and Form Group Styling */
.contact-form,
.form-group {
  font-family: 'Barlow';
}

/* Textbox Focus Styling */
.form-control:focus {
  border-color: #A28767 !important; /* Change border color to #A28767 */
  box-shadow: 0 0 0 0.2rem rgba(162, 135, 103, 0.25) !important; /* Change shadow to match the border */
  outline: none !important; /* Remove the default outline */
}


/* Remove default highlight color on input focus for all browsers */
*::-webkit-focus-ring-color {
  color: transparent !important;
  outline: none !important; /* Chrome-specific override */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus {
  background-color: #FFF !important;
  border-color: #A28767 !important;
  box-shadow: none !important;
  outline: none !important;
}

select option:hover,
select option:focus,
select option:active {
  background-color: #FFF !important;
  color: #402E32 !important;
}

/* Remove blue tap highlight color on touch devices */
* {
  -webkit-tap-highlight-color: transparent !important;
}
