.thumbnail {
    cursor: pointer;
    margin-bottom: 10px;
    max-width: 100px; /* Adjust the width to fit nicely on mobile */
    border: 2px solid transparent;
  }
  h2{
    font-family: 'Playfair Display';

  }
  .thumbnail img {
    width: 100%;
    height: auto;
  }
  
  .thumbnail:hover,
  .thumbnail:focus {
    border-color: #A28767;
  }
  
  .carousel {
    max-width: 100%;
    margin: 0 auto;
  }
  
  .product-details {
    margin-top: 20px;
  }
  
.carousel img {
  width: auto; /* Automatically adjust width to maintain aspect ratio */
  height: auto; /* Automatically adjust height to maintain aspect ratio */
  max-width: 100%; /* Ensure image does not exceed the container width */
  max-height: 100%; /* Ensure image does not exceed the container height */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767.98px) {
  .carousel img {
    max-height: 400px; /* Adjust this based on your image aspect ratio */
    object-fit: contain; /* Ensures the image is scaled down to fit, while preserving aspect ratio */
  }
}
