@font-face {
  font-family: 'Playfair Display';
  src: url('./Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Regular';
  src: url('./DM_Serif_Display/DMSerifDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Italic';
  src: url('./DM_Serif_Display/DMSerifDisplay-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Light Italic';
  src: url('./Barlow/Barlow-LightItalic.ttf') format('truetype');
}

.welcome-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.welcomeHeading {
  font-family: 'Playfair Display', serif;
  color: #AC3F1F;
  text-align: center;
  margin-bottom: 20px;
}

.headingText {
  font-family: 'Barlow Light Italic';
  text-align: center;
  margin: 0 auto;
  padding: 0 20px; 
}

@media (min-width: 576px) {
  .headingText {
    margin-left: 40px;
    margin-right: 40px;
  }
}
