
.carousel {
  color: #AC3F1F;
  position: relative;
}

.carousel-item-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px; /* Adjust height as needed */
}

.carousel-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 420px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .carousel-image {
    max-height: 300px; /* Adjust height for smaller screens */
  }
}
