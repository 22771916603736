/* Importing Fonts */
@font-face {
  font-family: 'Barlow';
  src: url('./Barlow/Barlow-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow Medium';
  src: url('./Barlow/Barlow-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Playfair Display';
  src: url('./Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Regular';
  src: url('./DM_Serif_Display/DMSerifDisplay-Regular.ttf') format('truetype');
}

/* Body Font */
body {
  font-family: 'Barlow';
  margin: 0; /* Remove default margin */
}

/* Background and Container Styling */
.bg-light {
  background-color: #f8f9fa;
}

.container {
  padding: 0; /* Remove default padding */
}

/* Centering the Card */
.row {
  margin: 0; /* Remove default margin */
}

.enquiry-card {
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto; /* Center card horizontally */
}

/* Card Body Styling */
.card-body {
  padding: 20px;
}

.product-heading {
  font-family: 'Barlow Medium';
  font-weight: bold;
}

/* Select Dropdown Styling */
.contact-method-select {
  background-color: #FFF;
  color: #402E32;
  border-color: #A28767 !important;
}

.contact-method-select:focus {
  border-color: #A28767 !important;
  box-shadow: 0 0 0 0.2rem rgba(172, 63, 31, 0.25) !important;
}

/* Button Styling */
.ms-2 {
  background-color: #FFFF !important;
  border-color: #9F6E5C !important;
  color: #402E32 !important;
  font-family: 'Barlow' !important;
  position: relative;
}

.ms-2::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.ms-2:hover::after {
  background-color: #AC3F1F;
}

/* Form Group Styling */
.mb-4 {
  border-radius: 10px;
  border-color: #A28767 !important;
  font-family: 'Barlow' !important;
}

/* Label Styling */
.form-label {
  color: #000000;
}

/* Card Background */
.card {
  background-color: #f9f9f9;
}

/* Focus and Hover States */
.form-control:focus,
.form-select:focus {
  border-color: #A28767 !important;
  box-shadow: 0 0 0 0.2rem rgba(172, 63, 31, 0.25);
  outline: none;
}

/* Remove default highlight color on input focus for all browsers */
*::-webkit-focus-ring-color {
  color: transparent !important;
  outline: none !important; /* Chrome-specific override */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus {
  background-color: #FFF !important;
  border-color: #A28767 !important;
  box-shadow: none !important;
  outline: none !important;
}

select option:hover,
select option:focus,
select option:active {
  background-color: #FFF !important;
  color: #402E32 !important;
}

/* Remove blue tap highlight color on touch devices */
* {
  -webkit-tap-highlight-color: transparent !important;
}
