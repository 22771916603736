.card-img-top {
  display: block;
  max-width: 100%; 
  max-height: 100%; 
  width: auto; 
  height: auto;
  margin-left: auto; 
  margin-right: auto; 
  object-fit: contain; 
}


.btn.btn-primary {
  background-color: #FFFF !important;
  border-color: #9F6E5C !important;
  color: #402E32 !important;
  font-family: 'Barlow' !important;
  position: relative; 
}

.btn.btn-primary::after {
  content: ''; 
  position: absolute; 
  left: 0; 
  bottom: 0; 
  width: 100%; 
  height: 2px; 
  background-color: transparent; 
  transition: background-color 0.3s ease; 
}

.btn.btn-primary:hover::after {
  background-color: #AC3F1F; 
}
.card {
  display: flex;
  justify-content: center; 
}

.card-body {
  text-align: center; 
}

.card-text {
  text-align: center;
  font-weight: bold;
}

.card-title {
  font-family: 'Playfair Display';
  font-size: larger;
  text-align: center;
}

.card {
  border: none; 
}

.card-body {
  border: none; 
}

/* Responsive Design */
@media (max-width: 991px) {
  .card {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .card-img-top {
    max-width: 100%;
    height: auto;
  }
}
