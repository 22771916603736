/* For WebKit browsers */
::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #AC3F1F; /* Thumb color */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #801e0c; /* Thumb color on hover */
  }
  